import React from 'react';

import Hero from '../hero';

function Main() {
  return (
    <main>
      <Hero />
    </main>
  )
}

export default Main;
