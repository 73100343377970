import React from 'react';
import wbPicture from '../../img/william-barela-0-900.jpg'

function Hero() {
  return(
    <article className="hero">
      <div className="hero--svg-container">
        <svg viewBox="50,0,750,900" className="hero--svg">
          <image href={wbPicture} width="900" height="900"></image>
        </svg>
      </div>
        <section className="hero--text">
          <h1>Hi! I'm William.</h1>
          <p>
            I'm a Full Stack Software Engineer and a former Linux System Admin.
            Thanks for stopping by.
          </p>
        </section>
    </article>
  )
}

export default Hero;
